@import '../../colors.scss';

.domainsDivContainer {
    background-color: $lightBlue;
}

.domainsDiv {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: black;
    max-width: 1200px;
    margin: auto;
    padding: 0 100px;

    @media only screen and (max-width: 1200px) {
        padding: 0;
    }

    .topBar {
        position: fixed;
        background-color: #0B0E1C;
        color: white;
        width: 100%;
        padding: 10px 0px;
        margin-top: 50px;
        box-sizing: border-box;
        display: none;
        flex-direction: column;
        padding-bottom: 10px;
        transition: all 0.4s;
        z-index: 2;


        &.open {
            padding-bottom: 50px;
        } 

        @media only screen and (max-width: 1200px) {
            display: flex;
        }

        .topBarHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 100px;

            @media only screen and (max-width: 600px) {
                padding: 0px 50px;
            }
            

            svg {
                transform: rotate(0deg);
                transition: all 0.4s;
            }
            svg.open {
                transform: rotate(180deg);
            }

        }
        .topBarOptions {
            max-height: 0;
            transition: all 0.4s;
            overflow: hidden;
            // padding-bottom: 50px;

            p {
                margin: 0;
                padding: 15px 100px;
                border-left: 2px solid transparent;
                transition: all 0.4s;

                @media only screen and (max-width: 600px) {
                    padding: 15px 50px;
                }
            }
            p.active {
                border-left: 2px solid $green;
            }
        }
        .topBarOptions.open {
            max-height: 1000px;
        }
    }

    .left {
        width: 320px;
        background-color: $lightBlue;
        // min-height: 100vh;
        padding: 100px;
        padding-right: 50px;
        padding-left: 0px;
        // padding-right: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // position: absolute;
        margin-top: 30px;

        @media only screen and (max-width: 1200px) {
            display: none;
        }

        h1 {
            margin-bottom: 10px; 
        }
        hr {
            clear:both;
            border: none;
            height: 3px;
            background-color: $green;
            // border: 1px solid black;
            width: 40%;
            margin: 0;
            margin-bottom: 20px;
            border-radius: 5px;
        }
        .domainListRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .bullet {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 10px;
    
                .leftCircle{
                    width: 0px;
                    height: 0px;
                    background-color: $green;
                    border-radius: 10px;
                    transition: all 0.4s ease;
                }
                .rightCircle {
                    width: 10px;
                    height: 10px;
                    background-color: $green;
                    border-radius: 10px;
                    transition: all 0.4s ease;
                }
                .leftCircle.active{
                    width: 10px;
                    height: 10px;
                }
                .centerBar {
                    width: 0px;
                    height: 3px;
                    background-color: $green;
                    transition: all 0.4s ease;
    
                    @media only screen and (max-width: 600px) {
                        width: 10px;
                    }
                }
                .centerBar.active {
                    width: 30px;
                    height: 3px;
                }
            }

            h3 {
                font-weight: 400;
                margin: 10px 0;
                cursor: pointer;
                transition: color 0.4s;
                transition: all 0.4s;
                position: relative;
                left: 0px;
    
                &:hover {
                    // color: $green;
                }
            }
            h3:hover {
                // font-weight: 600;
                left: 5px;
            }
            h3.active {
                font-weight: 600;
                left: 0px;
            }
        }
    }
    .right {
        flex: 1;
        background-color: $lightBlue;
        min-height: 100vh;
        padding: 100px;
        padding-left: 0;
        padding-right: 0;
        // margin-left: 400px;
        margin-top: 30px;

        @media only screen and (max-width: 1200px) {
            margin-left: 0;
            padding: 0px;
            margin-top: 170px;
        }

        h1 {
            font-size: 3em;
            margin-top: 0;

            @media only screen and (max-width: 1200px) {
                margin-left: 100px; 
                margin-right: 100px; 
            }
            @media only screen and (max-width: 600px) {
                font-size: 2em;
                margin-left: 50px; 
                margin-right: 50px; 
            }
        }

        hr {
            border: 0;
            border-top: 1px solid $darkBlue;
            // border-style: solid;
            // height:0;
        }

        .industryDiv {
            // background-color: white;
            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px 30px;

            img {
                max-height: 160px;
                max-width: 100%;
            }
        }

        > p {
            @media only screen and (max-width: 1200px) {
                margin-left: 100px; 
                margin-right: 100px; 
            }
            @media only screen and (max-width: 600px) {
                margin-left: 50px; 
                margin-right: 50px; 
            }
        }
        .projectsDiv {
            background-color: white;
            padding: 50px 50px;
            margin: 0; 
            margin-top: 50px;
            border-radius: 8px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            @media only screen and (max-width: 1200px) {
               padding: 50px 100px;
            }
            @media only screen and (max-width: 600px) {
               padding: 50px;
            }

            h2 {
                font-size: 2.5em;
                margin-top: 0;
                font-weight: 600;

                @media only screen and (max-width: 1200px) {
                    font-size: 1.7em;
                }
            }
            .expandableBar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                width: 100%;

                button {
                    background: none;
                    color: inherit;
                    border: none;
                    padding: 20px 0;
                    font: inherit;
                    outline: inherit;
                    font-weight: 600;
                    font-size: 1.2em;
                    cursor: pointer;
                }

                &:hover{
                    button{
                        // color: $green;
                    }
                }
            }
            .content {
                width: 100%;
                overflow: hidden;
                max-height: 2500px;
                transition: .5s ;
                display: grid;
                column-gap: 20px;
                row-gap: 20px;
                grid-template-columns: repeat( auto-fit, minmax( 250px, 1fr) );
                align-items: start;
                margin-bottom: 30px;
            }
            .content.hide {
                // display: none;
                transition: all .5s;
                max-height: 0;
                margin-bottom: 0px;
            }
            hr {
                opacity: 0.3;
                display:block;
                width: 100%;
            }
        }
        .teamDiv{
            h2 {
                font-size: 2em;
                font-weight: 500;
                @media only screen and (max-width: 1200px) {
                    // padding: 0 100px;
                    font-size: 2em;
                }
                @media only screen and (max-width: 600px) {
                    font-size: 1.5em;
                }

                &.center {
                    text-align: center;
                }
            }

            @media only screen and (max-width: 1200px) {
                padding: 0 100px;
            }
            @media only screen and (max-width: 600px) {
                padding: 0 50px;
            }
        }
    }
}