@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500&display=swap');
@import './colors.scss';

html {
  // scroll-behavior: smooth;
}

body {
  // background-color: $base-color;
  margin: 0;
  // padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  display: flex;
  flex-direction: row;
}
.justify {
  text-align: justify;
}
.button-light {
  background-color: $green;
  padding: 10px 30px;
  border-radius: 8px;
  color: black;
  font-weight: 800;

  &:hover {
    background-color: #1cf8bda9;
    cursor: pointer;
    transition: .3s;
  }
}
.button-dark {
  background-color: $darkBlue;
  padding: 10px 30px;
  border-radius: 8px;
  color: white;
  font-weight: 800;
  text-decoration: none;

  &:hover {
    background-color: #131833ec;
    cursor: pointer;
    transition: .3s;
  }
}

::selection {
  background: #1cf8bda4; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #1cf8bda4; /* Gecko Browsers */
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.281);
  // overflow: overlay; 
}

::-webkit-scrollbar {
  width: 4px;
  background: #075f5288;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $green;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $green;
}