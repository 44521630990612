@import '../../colors.scss';

.projectDiv {
    padding: 100px 300px;
    min-height: 100vh;
    background-color: $lightBlue;

    @media only screen and (max-width: 1200px) {
        padding: 100px;
    }
    @media only screen and (max-width: 600px) {
        padding: 100px 50px;
    }

    .projectDivContainer {
        max-width: 1000px;
        margin: auto;
    }

    h1 {
        font-size: 2.4em;

        @media only screen and (max-width: 600px) {
            font-size: 1.8em;
        }
    }

    a {
        text-decoration: underline;
    }

    .noteDiv{
        width: 100%;
        background-color: #8cdb812c;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;

        .noteHeadingRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            opacity: .6;

            p {
                font-weight: bold;
                margin: 0;
                margin-left: 5px;
            }
        }
        
        > p{
            opacity: .6;
            // font-weight: 500;   
            margin-bottom: 0;
            text-align: center;
            margin-top: 5px;
        }
    }

    .subheadingRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;

        .point {
            height: 15px;
            width: 15px;
            background-color: $green;
            margin-right: 10px;
            border-radius: 10px;
        }
        h2 {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .bulletRow {
        display: flex;
        flex-direction: row;
        margin-left: 30px;

        @media only screen and (max-width: 600px) {
            margin-left: 0px;
        }

        .bullet {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 10px;

            .left , .right {
                width: 10px;
                height: 10px;
                background-color: $green;
                border-radius: 10px;
            }
            .center {
                width: 30px;
                height: 3px;
                background-color: $green;

                @media only screen and (max-width: 600px) {
                    width: 10px;
                }
            }
        }
    }
    .button-dark {
        margin-top: 120px;
    }
    .body {
        margin-bottom: 50px;

        h4 {
            margin-bottom: 0;
        }
    }
    img {
        width: 100%;
    }
}