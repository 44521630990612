@import '../../colors.scss';

.projectCardsDiv {
    width: 100%;
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat( auto-fit, minmax( 300px, 1fr) );
    align-items: start;

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
}

.projectCardDiv {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.164);
    border-radius: 8px;
    overflow: visible;
    padding: 20px;
    transition: all .4s;
    color: black;

    &:hover {
        cursor: pointer;
        background-color: $lightBlue;
    }

    .tag{
        font-size: .7em;
        text-transform: uppercase;
        background-color: $green;
        padding: 2px 6px;
        border-radius: 4px;
        margin: 0;
        margin-bottom: 10px;
        width: fit-content;
        font-weight: 500;
    }

    h3 {
        // font-size: small;
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
    }
    #projectCardDesc {
        font-size:small;
        margin-left: 0;
        margin-right: 0;
    }
    #readMore {
        color: $purple;
        font-weight: 600;
        font-size: small;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
}