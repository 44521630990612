@import '../../colors.scss';

.contactDiv {
    overflow: hidden;
    background-color: $lightBlue;
    height: 100%;
    min-height: 100vh;

    .contactSection1Container {
        background-color: $darkBlue;
        width: 100%;
    }
    
    .contactSection1 {
        // background-color: $lightBlue;
        // background-color: red;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 100px;
        // margin-bottom: 100px;
        max-width: 1200px;
        margin: auto;
        flex-direction: column;
        // align-items: center;
        // height: 500px;

        @media only screen and (max-width: 1200px) {
            
        }
        @media only screen and (max-width: 600px) {
            padding: 0 50px;
        }

        h1 {
            color: $green;
            font-size: 4em;
            z-index: 1;
            font-weight: 500;
            margin-top: 200px;
            width: 100%;
            text-align: center;

            // @media only screen and (min-width: 1300px) {
            //     // margin-top: 300px;
            // }
            // @media only screen and (max-width: 1300px) {
            //     font-size: 4em;
            // }
            @media only screen and (max-width: 600px) {
                font-size: 2.4em;
                // margin-top: 150px;
            }
        }

        .contactDetails {
            color: white;
            margin-bottom: 40px;

            .row {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                max-width: 500px;
                padding: 5px;

                p {
                    margin: 0;
                    margin-left: 10px;
                }
                svg {
                    margin-top: 2px;
                    width: 20px;
                    height: auto;
                }
            }
        }
    }
    .contactSection2Container {
        width: 100%;
        background-color: $lightBlue;
        padding: 100px;
        box-sizing: border-box;
        
        @media only screen and (max-width: 600px) {
            padding: 0px;
        }
    }
    .contactSection2 {
        min-height: 50vh;
        max-width: 1200px;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @media only screen and (max-width: 1000px) {
            flex-direction: column;
        }

        .contactSection2Left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            box-sizing: border-box;

            h3 {
                font-size: 2em;
                font-weight: 500;
                margin: 0;
                margin-bottom: 10px;
                margin-top: 100px;
                
                @media only screen and (max-width: 600px) {
                    margin-top: 0px;
                    padding: 50px;
                    padding-bottom: 0;
                }
            }
            p {
                margin: 0;
                font-size: 1.2em;
                color: rgba(0, 0, 0, 0.726);
                margin-right: 50px;

                @media only screen and (max-width: 600px) {
                    padding: 0 50px;
                }
            }
        }

        .card {
            // height: 500px;
            flex: 1;
            background-color: white;
            // position: relative;
            // top: -50px;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px; 
            box-sizing: border-box;
            border-radius: 8px;

            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            @media only screen and (max-width: 1200px) {
                width: 100%;
                margin: 100px 0;
            }
            @media only screen and (max-width: 600px) {
                width: 100%;
                border-radius: 0;
                margin: 50px 0;
            }

            h3 {
                margin: 30px auto;
            }

            input{
                padding: 10px;
                border: 1px solid rgba(0, 0, 0, 0.226);
                border-radius: 4px; 
                background-color: transparent;
                width: 100%;
                box-sizing: border-box;
                font-size: 1em;
                margin-bottom: 20px;
                font-family:"Helvetica Neue", Helvetica, sans-serif; 

                &:focus{
                    // border: rgb(180, 180, 180) solid 1px;
                    outline: none;
                }
                &::placeholder{
                    color: #B8BCCA;
                }
            }
            select {
                padding: 10px;
                // padding-right: 30px;
                border: none;
                background-color: #F8FAFC;
                width: 100%;

            }
            .contactDropdown {
                width: 100%;
                margin-bottom: 20px;
            }
            textarea {
                box-sizing: border-box;
                width: 100%;
                padding: 10px;
                border: 1px solid rgba(0, 0, 0, 0.226);
                border-radius: 4px;
                background-color: transparent;
                resize: vertical;
                font-size: 1em;
                // font-family: 'Poppins' sans-serif;
                font-family:"Helvetica Neue", Helvetica, sans-serif; 
                margin-bottom: 20px;

                &:focus{
                    // border: rgb(180, 180, 180) solid 1px;
                    outline: none;
                }
                &::placeholder{
                    color: #B8BCCA;
                    // font-family: 'Poppins', sans-serif;
                    font-family: inherit;
                }
            }

            .sendRow {
                display: flex;
                flex-direction: row;
                align-items: center;

                .button-dark {
                    // display: inline-block;
                    // width: 100px;
                    border: none;
                    margin-top:20px;
                    // margin-bottom: 20px;
                }
                .statusMessage {
                    font-weight: bold;
                    margin-left: 20px;
                    margin-right: 10px;
                }
                .tickIcon {
                    // color: $green;
                }
            }
        }
    }
}