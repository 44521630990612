@import '../../colors.scss';


.navbarDivContainer {
    position: fixed;
    padding: 0 100px;
    box-sizing: border-box;
    z-index: 4;
    width: 100%;
    background-color:  #0B0E1C;
    transition: .4s;
    height:50px;
    
    @media only screen and (max-width: 1200px) {
        padding: 0;
    }
}
.navbarDiv {
    color: white;
    height:100%;
    
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: .4s;
    max-width: 1200px;
    margin: auto;

    @media only screen and (max-width: 1200px) {
        padding: 0;
    }


    .leftDiv{
        height: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .hamburger {
            color: white;
            width: 100px;
            @media only screen and (min-width: 1200px) {
                display: none;
            }
            @media only screen and (max-width: 600px) {
                width: 50px;
            }
        }
        a {
            height: 100%;
            .logo {
                // background-color: red;
                height: 100%;
            }
        }
    }

    .rightDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        @media only screen and (max-width: 1200px) {
            display: none;
        }

        .underline {
            position: absolute;
            bottom: -6px;
            left: 0;
            right: 0;
            height: 3px;
            background: $green;
            // width: 30px;
            border-radius: 5px;
            opacity: 1;
            transition: background 0.4s;
            transition-delay: .6s;

            &.hide {
                background: transparent;
            }
          }

        > a, .dropdown > .menu-item > a, p, .searchIcon {
            margin: 0;
            margin-left: 30px;
            font-size: small;
            transition: .4s;
            color: white;
            text-decoration: none;
            position: relative;

            &:hover {
                cursor: pointer;
                color: $green;
            }
        }
        .searchIcon{
            display: flex;
            align-items: center;
            svg{
                transform: scaleX(-1);
            }
        }
        
        .dropdown{
            display: flex;
            flex-direction: column;
            // background-color: red; 

            .dropbtn {
                cursor: default;
            }
        }

        .container {
            margin-top: 50px;
            // max-width: 500px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          
          .menu-item {
            // width: 200px;
            // perspective: 2000px;
            display: flex;
          }
          
          .sub-menu {
            position: absolute;
            top: 30px;
            padding: 15px;
            background-color: #0B0E1C;
            border-radius: 0 0 8px 8px;
            margin-left: 30px;
          
            transform-origin: 50% -30px;
          }
          
          .sub-menu-container {
              display: flex;
              flex-direction: column;
        
              a {
                  color: white;
                  font-size: small;
                margin: 10px;
                transition: all 0.4s;
        
                &:hover {
                    color: $green;
                }
              }
          }
          
          .sub-menu-item {
            margin-bottom: 5px;
          }
          
    }
    .sidebar {
        height: 100vh;
        min-width: 300px;
        background-color: #0B0E1C;
        position: absolute;
        top: 0;
        transition: all 0.4s;
        left: 0;

        .closeDiv {
            width: 100px;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transition: all .4s;

            @media only screen and (max-width: 600px) {
                width: 50px;
            }

            .closeIcon {
                // width: 50px;
                height: 50px;
                margin: auto;
            }
        }
        .closeDiv.top{
            height: 70px;   
        }
        .itemsList {
            display: flex;
            flex-direction: column;
            padding: 50px 0;
            max-width:300px;
            // align-items: flex-start;

            a {
                color: white;
                text-decoration: none;
                font-size: large;
                padding: 15px 50px;
                border-left: 2px solid transparent;
                transition: all 0.4s;
            }
            a.active {
                border-left: 2px solid $green;
            }
        }
    }
    .sidebar.close {
        left: -300px;
    }
}
.top{
    height: 70px;
}
.transparent {
    background-color: transparent;

    &:hover {
        background-color:  #0B0E1C;
    }
}
.toTopIcon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: $green;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
    transition: all 0.4s;

    &.show {
        opacity: 100%;
    }
}
.toTopDiv {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
    transition: all 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    border-radius: 50px;
    background-color: $lightBlue;

    @media only screen and (max-width: 600px) {
        display: none;
    }

    &.show {
        opacity: 70%;
    }
    &:hover {
        bottom: 25px;
        opacity: 100%;
        svg {
            margin-bottom: 10px;
        }
    }

    p {
        transform: rotate(-90deg);
        text-transform: uppercase;
        font-weight: bold;
        margin: 30px -10px;
        padding: 0;
    }
    svg {
        margin-top: 10px;
        margin-bottom: 0;
        transition: all 0.4s;
    }
}