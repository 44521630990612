@import '../../colors.scss';

.facultyCardsDiv {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat( auto-fill, minmax( 200px, 1fr) );
    margin-bottom: 50px;

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }
    .facultyCardDiv {
        min-height: 100px;
        background-color: white;
        border-radius: 8px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        // cursor: pointer;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
        &:hover {
            img {
                border: 3px solid $green;
                // -webkit-box-shadow:inset 0px 0px 0px 10px #f00;
                // -moz-box-shadow:inset 0px 0px 0px 10px #f00;
                // box-shadow:inset 0px 0px 0px 10px #f00;
            }
        }
    
        @media only screen and (max-width: 600px) {
            flex-direction: row;
            padding: 20px;
          }

        .imageWrapper {
            width:80%;
            height:0; 
            padding-top:80%;
            position:relative;

            @media only screen and (max-width: 600px) {
                width: 30%;
                height: auto;
                padding-top: 0;;
                // position: inherit;
            }
        }
    
        img {
            width: 100%;
            box-sizing: border-box;
            position:absolute; top:0; left:0; width:100%;
            border-radius: 50%;
            border: 3px solid transparent;
            transition: all 0.4s;
            @media only screen and (max-width: 600px) {
                // width: 80px;
                position: relative;
              }
        }
        .detailsDiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media only screen and (max-width: 600px) {
                align-items: flex-start;
                margin-left: 30px;
              }
        }
        h4 {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: 10px;
            margin-top: 20px;
            text-align: center;
    
            @media only screen and (max-width: 600px) {
                margin-top: 0;
                text-align: left;
                font-size: 1em;
              }
        }
        p {
            margin: 0;
            text-align: center;
            margin-bottom: 5px;

            @media only screen and (max-width: 600px) {
                text-align: left;
              }
        }
        p.cardTitle {
            opacity: .6;
            font-weight: 500;
            font-size: small;
        }
        p.cardOrg {
            font-weight: 600;
            font-size: small;
        }
        .iconsRow {
            display: flex;
            flex-direction: row;
        }
        svg {
            color: $darkBlue;
            margin: 5px;
            margin-bottom: 0;

            @media only screen and (max-width: 600px) {
                margin-left: 0;
                margin-right: 10px;
              }
        }
    }