@import '../../colors.scss';

.aboutDiv{
    overflow: hidden;

    .aboutSection1Container {
        background-color: $darkBlue;
        padding: 0 100px;
        overflow: hidden;
        position: relative;

        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        @media only screen and (max-width: 600px) {
            padding: 0 50px;
            height: 100vh;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        object {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -10%;
            width: 80%;
            min-width: 800px;
            overflow: hidden;

            @media only screen and (max-width: 1200px) {
                margin: auto;
                position: static;
                transform: none;
                margin-top: -100px;
                margin-bottom: -200px;
                margin-left: 50%;
                transform: translateX(-50%);
            }
            @media only screen and (max-width: 700px) {
                // width: 70%;
            }
            @media only screen and (max-width: 900px) {
                // width: 50%;
            }
        }
    }

    .aboutSection1 {
        // min-height: 80vh;
        height: 700px;
        max-height: 100vh;
        // background-color: $lightBlue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        max-width: 1200px;
        margin: auto;

        @media only screen and (max-width: 1200px) {
            height: auto;
            padding-bottom: 100px;
        }


        h1 {
            font-size: 5em;
            // width: 40%;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 20px;
            color: $green;

            @media only screen and (max-width: 600px) {
                font-size: 3em;
            }
            @media only screen and (max-width: 1200px) {
                // width: 100%;
                // padding-top: 50px;
            }
        }
        p {
            width: 45%;
            margin: 0;
            color: white;

            @media only screen and (max-width: 1200px) {
                width: 100%;
                // padding-bottom: 300px;
            }
        }

        .il{
            position: absolute;
            right: 0;
            height: 50px;
            width: 100px;
            background-color: $green;
            border-radius: 50px 0 0 50px;

            @media only screen and (max-width: 1200px) {
                bottom: 0;
                transform: rotate(90deg);
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; 
            }
        }
    }
    .aboutSection2{
        padding: 50px 300px;
        // margin: 100px 0;
        max-width: 1000px;
        margin: auto;
        // text-align: justify;

        .aboutSection2Container{
            max-width: 1000px;
            margin: auto;
        }

        @media only screen and (max-width: 1200px) {
            padding: 75px;
            // margin: 100px 0;
          }
        @media only screen and (max-width: 600px) {
            padding: 50px;
            // margin: 100px 0;
          }

        p {
            margin-bottom: 20px;
        }
        ol > li::marker {
            font-weight: bold;
          }
    }
    .aboutSection3 {
        // min-height: 100vh;
        background-color: $lightBlue;
        padding: 100px;
        padding-top: 50px;
        
        .aboutSection3Container {
            max-width: 1200px;
            margin: auto;
        }

        @media only screen and (max-width: 600px) {
            padding: 50px;
          }

        h2 {
            font-size: 3em;
            color: $darkBlue;
            margin: 10px 0;

            @media only screen and (max-width: 600px) {
                font-size: 2em;
              }
        }
        h3 {
            font-size: 1.5em;
            color: $darkBlue;

            @media only screen and (max-width: 600px) {
                font-size: 1.2em;
              }
        }

        .subheadingRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .bullet {
                height: 1em;
                width: 1em;
                background-color: $green;
                margin-right: 15px;
                border-radius: 50%;
            }
        }
        .studentGridDiv {
            // overflow: hidden;
            .studentRow {
                // width: 80%;
                margin: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .wire {
                    height: 5px;
                    width: 70px;
                    background-color: $green;

                    @media only screen and (max-width: 600px) {
                        width: 30px;
                        height: 3px;
                      }
                }
            }
        }
    }

}
