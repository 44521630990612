@import '../../colors.scss';

.collaborateDiv {
    box-sizing: border-box;
    overflow: hidden;

    .collaborateSection1Container {
        background-color: $darkBlue;
        padding: 0 100px;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;

        @media only screen and (max-width: 600px) {
            padding: 0 50px;
            // padding-bottom: 250px;
        }

        .il1 {
            position: absolute;
            right: 0;
            top: 30%;

            @media only screen and (min-width: 1400px) {
                width: 700px;
                height: auto;
                margin-top: auto;
                margin-bottom: auto;
                top: 30%;
            }
            @media only screen and (min-width: 1600px) {
                width: 1000px;
                height: auto;
                top: 25%;
            }
            @media only screen and (max-width: 1200px) {
                bottom: -50px;
                top: auto;
                transform: rotate(90deg);
                right: 5%;
                height: 35%;
            }
            @media only screen and (max-width: 600px) {
                transform: rotate(90deg);
                left: 0px; 
                top: 70%;
                height: 350px;
                margin-left: auto; 
                margin-right: auto; 
            }
        }
    }

    .collaborateSection1 {
        height: 800px;
        max-height: 100vh;
        color: $green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // overflow: hidden;
        box-sizing: border-box;
        max-width: 1200px;
        margin: auto;
        position: relative;

        @media only screen and (max-width: 600px) {
            height: 100vh;
        }

        h1 {
            margin-top: 0;
            padding-top: 15%;
            font-size: 5em;
            width: 30%;
            line-height: 120%;
            z-index: 2;
            font-weight: 600;
            margin-bottom: 30px;

            @media only screen and (min-width: 1500px) {
                font-size: 6em;
            }
            @media only screen and (max-width: 1200px) {
                width: 100%;
            }
            @media only screen and (max-width: 600px) {
                font-size: 2.8em;
            }
        }
        .buttonRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @media only screen and (max-width: 1200px) {
                flex-direction: column;
                align-items: stretch;
            }
            .button-light {
                z-index: 2;
                @media only screen and (max-width: 1200px) {
                    margin-bottom: 10px;
                }
            }

            div {
                margin-right: 15px;
            }
        }
    }
    .collaborateSection2 {
        // height: 300vh;
        position: relative;
        padding: 100px;
        padding-top: 0;
        background-color: $lightBlue;
        // display: flex;
        // flex-direction: column;

        @media only screen and (max-width: 600px) {
            padding: 0;
        }

        img {
            position: absolute; 
            // left: 0; 
            left: 25%;
            top: 100px;
            margin-left: auto; 
            margin-right: auto; 
            // width: 45%; /* Need a specific value to work */
            height: 80%; /* Need a specific value to work */
            z-index:0;
            box-sizing: border-box;

            @media only screen and (max-width: 1000px) {
                display: none;
            }
        }
        .collaborateEach {
            // height: 100vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            z-index: -1;
            // padding-bottom: 100px;
            padding-top: 50px;
            &.firstChild {
                padding-top: 100px;
            }
            

            h1 {
                margin-bottom: 0;
            }
        }
        .right {
            padding-left: 50%;
            padding-right: 10%;
            // padding: 0;
            box-sizing: border-box;

            @media only screen and (max-width: 1000px) {
                padding: 50px;
            }
        }
        // .left {
        //     padding-right: 50%;
        //     padding-left: 10%;
        // }
    }
}