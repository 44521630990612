@import '../../colors.scss';


.footerContainer {
    background-color: $darkBlue;
}

.footerDiv {
    min-height: 300px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 50px;
    overflow: hidden;
    box-sizing: border-box;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }

    .logo {
        height: 100px;

        @media only screen and (max-width: 800px) {
            height: 70px;
        }
        @media only screen and (max-width: 600px) {
            height: 50px;
            margin-bottom: 20px;
        }
    }
    .footerColumn {
        display: flex;
        flex-direction: column;
        margin-left: 70px;

        @media only screen and (max-width: 800px) {
            margin-left: 40px;
        }
        @media only screen and (max-width: 600px) {
            margin-left: 0;
            align-items: center;
        }

        a {
            color: white;
            text-decoration: none;
            margin-bottom: 10px;
            transition: .4s;
            z-index: 2;

            &:hover {
                color: $green;
                cursor: pointer;
            }
        }
        h3 {
            margin: 0;
            z-index: 2;

            @media only screen and (max-width: 600px) {
                margin-top: 20px;
            }
        }
        .iconRow {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            z-index: 2;
            .icon{
                font-size: 1.4em;
                margin-right: 10px;
            }
        }
    }
    .il {
        position: absolute;
        overflow: hidden;
        right: 0%;
        // bottom: 0;
        // background-color:red;
        width: 100%;
        pointer-events: none;

        @media only screen and (max-width: 1200px) {
            width: auto;
            bottom: 20px;
        }
        @media only screen and (max-width: 400px) {
            right: -70px;
        }
    }
}
.copyrightDiv {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    padding-top: 0;
    font-size: small;
    text-align: center;

    a {
        text-decoration: underline;
        color: $green;
    }
}