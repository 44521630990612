@import '../../colors.scss';

.loadingDiv {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: $darkBlue;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    
    h1 {
        color: white;
    }
}