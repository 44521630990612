@import '../../colors.scss';

.howWeWorkDiv {

    .section1Container {
        background-color: $darkBlue;
        padding: 0 100px;
        overflow: hidden;

        @media only screen and (max-width: 600px) {
            padding: 0 50px;
        }
    }

    .section1 {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 100px 0;
        box-sizing: border-box;
        color: white;
        // overflow: hidden;
        position: relative;
        max-width: 1200px;
        margin: auto;

        @media only screen and (max-width: 600px) {
            padding: 50px 0;
        }

        h1 {
            font-size: 5em;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 150px;
            color: $green;

            @media only screen and (min-width: 1500px) {
                font-size: 6em;
            }
            @media only screen and (max-width: 600px) {
                font-size: 4em;
            }
        }

        .il1 {
            position: absolute;
            margin-top: 350px;
            height: 900px;
            width: auto;

            @media only screen and (min-width: 1400px) {
                width: 150%;
                height: auto;
            }
            @media only screen and (max-width: 600px) {
                margin-top: 200px;
            }
        }
    }
    .section2 {
        padding: 100px 300px;
        max-width: 1000px;
        margin: auto;

        @media only screen and (max-width: 1200px) {
            padding: 100px 150px;
        }
        @media only screen and (max-width: 600px) {
            padding: 100px 50px;
        }

        p {
            margin-bottom: 50px;
        }
    }
}