@import '../../colors.scss';

.eventCard {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    color: black;
    transition: all 0.4s;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .imgDiv{
        background-position: 50% 50%;
        background-size: cover;
        height: 200px;
    }
    .contentDiv{
        padding: 20px;
        position: relative;

        .category {
            color: $purple;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-size: smaller;
            font-weight: 600;
        }

        h3 {
            font-weight: 500;
            margin-top: 0;
        }

        .arrow {
            float: right;
            margin-bottom: 20px;
            margin-right: 10px;
            // background-color: green;
            transition: all 0.4s ease;
        }
        svg {
            color: green;
        }
        .bubble {
            height: 70px;
            width: 70px;
            background-color: $green;
            border-radius: 50%;
            position: absolute;
            top: -35px;
            right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                margin: 0;
                line-height: 110%;
                font-size: smaller;
            }
            h3.large {
                font-size: 1.5em;
            }
        }
    }
    &:hover {
        .arrow {
            margin-right: 0;
        }
    }
}