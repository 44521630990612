@import '../../colors.scss';

.blogsDiv {
    .blogsSection1Container {
        background-color: $darkBlue;
        overflow: hidden;
        padding: 0 100px;

        @media only screen and (max-width: 600px) {
            padding: 0 50px;
        }
    }
    .blogsSection1 {
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        position: relative;
        box-sizing: border-box;
        padding: 100px 0;
        max-width: 1200px;
        margin: auto;

        @media only screen and (max-width: 600px) {
            padding: 50px 0;
        }

        h1 {
            color: $green;
            font-size: 6em;
            font-weight: 500;
            @media only screen and (max-width: 600px) {
                padding-bottom: 100px;
                font-size: 4em;
                // margin-bottom: 150px;
            }
        }

        .il1 {
            position: absolute;
            right: -50px;
            top: 200px;
            width: 1000px;

            @media only screen and (max-width: 1200px) {
                left: 350px;
                right: auto;
            }
            @media only screen and (max-width: 600px) {
                top: 250px;
                left: -50px;
                width: 700px;
            }
        }
    }
    .blogsSection2 {
        // min-height: 70vh;
        // background-color: $darkBlue;
        background-color: white;
        // min-height: 100vh;
        padding: 100px;
        padding-top: 50px;
        max-width: 1200px;
        margin: auto;

        @media only screen and (max-width: 600px) {
            padding: 50px;
        }
    }
}